<template>
<div id="internal">

  <div class="hidden-sm-and-down">
    <PageHeader/>
  </div>
  <div class="hidden-md-and-up">
    <m-top/>
  </div>

  <div class="hidden-xs-only">
    <DietSidebar dietMainPage="price"/>
    
    <el-row type="flex" justify="center">
      <el-col :md="20" :lg="18" :xl="15">
        <div style="width: 100%; max-width: 1284px; margin: 0 auto;">
          <div class="side-box" style="margin-bottom: 30px; position: relative;width: 100%">
            <div class="title">メディカルダイエット</div>
            <div class="LINE_btn" @click="goHref('https://liff.line.me/1657916768-pJgN5enw/landing?follow=%40484tylso&lp=zFcyNv&liff_id=1657916768-pJgN5enw')">
              <div class="side-box">
                <img src="../../assets/SNS/line.png" style="height:50px">
                <div class="side-text-line">
                  <div style="font-size: 18px; text-align: center;">メディカルダイエット専用</div>
                  <div style="font-size: 18px; text-align: center;">お友達登録＋診療予約</div>
                </div>
              </div>
            </div>
          </div>

          

          <div class="separator">
            <div class="bg_blue">
              <div class="center-title">料　金</div>
              <table class="table_ryoukin">
              <tr>
                <td class="ryoukin_top ryoukin_name_corner" style="font-size: 32px;">(税込価格)</td>
                <td class="ryoukin_top ryoukin_name_side ryoukin_title_top">３０日分</td>
                <td class="ryoukin_top ryoukin_name_side ryoukin_title_top">６０日分</td>
                <td class="ryoukin_top ryoukin_name_corner ryoukin_title_top">９０日分</td>
              </tr>
              <tr>
                <td class="ryoukin_name_topdown ryoukin_title">リベルサス®3mg</td>
                <td class="ryoukin_name">¥7,500</td>
                <td class="ryoukin_name">¥15,000</td>
                <td class="ryoukin_name_topdown">¥22,500</td>
              </tr>
              <tr>
                <td class="ryoukin_name_topdown ryoukin_title">リベルサス®7mg</td>
                <td class="ryoukin_name">¥13,900</td>
                <td class="ryoukin_name">¥27,800</td>
                <td class="ryoukin_name_topdown">¥41,700</td>
              </tr>
              <tr>
                <td class="ryoukin_name_topdown ryoukin_title">リベルサス®14mg</td>
                <td class="ryoukin_name">¥24,800</td>
                <td class="ryoukin_name">¥49,600</td>
                <td class="ryoukin_name_topdown">¥74,400</td>
              </tr>
              <tr>
                <td class="ryoukin_name_topdown ryoukin_title">カナグル®100mg</td>
                <td class="ryoukin_name">¥11,300</td>
                <td class="ryoukin_name">¥22,600</td>
                <td class="ryoukin_name_topdown">¥33,900</td>
              </tr>
              <tr>
                <td class="ryoukin_name_corner ryoukin_title">防風通聖散<br>1日3包</td>
                <td class="ryoukin_name_side">¥5,300</td>
                <td class="ryoukin_name_side">¥10,600</td>
                <td class="ryoukin_name_corner">¥15,900</td>
              </tr>
              </table>

              <div class="content1" style="height: 75px;"></div>

              <div class="content1">
                <div class="ryoukin_title_top" style="color: #FFFFFF; margin-left: 50px;">その他にかかる費用</div>                 
              </div>

              <div class="content1">
                <table class="table_ryoukin">
                  <tr>
                    <td class="ryoukin_top ryoukin_name_corner" style="font-size: 32px;">(税込価格)</td>
                    <td colspan="2" class="ryoukin_top ryoukin_name_side ryoukin_title_top">オンライン診療</td>
                    <td class="ryoukin_top ryoukin_name_corner ryoukin_title_top">来院診療</td>
                  </tr>
                  <tr>
                    <td class="ryoukin_name_topdown ryoukin_title">初　　診</td>
                    <td colspan="2" class="ryoukin_name">￥2,200　(診療費＋送料)</td>
                    <td class="ryoukin_name_topdown">￥1,100　(診療費)</td>
                  </tr>
                  <tr>
                    <td class="ryoukin_name_corner ryoukin_title">再　　診</td>
                    <td colspan="2" class="ryoukin_name_side">￥1,100　(診療費＋送料)</td>
                    <td class="ryoukin_name_corner">￥  550　(診療費)</td>
                  </tr>
              </table>
              </div>
              
            </div>

          </div>


        </div>
      </el-col>
    </el-row>
    <PageFooter/>
  </div>

  <div class="hidden-sm-and-up">
    
    <MDietSidebar/>
    <div class="side-box" style="margin-bottom: 30px;">
          <div class="title">メディカルダイエット</div>
      </div>

        

    <div class="separator">

        <div class="m_LINE_btn"  @click="goHref('https://liff.line.me/1657916768-pJgN5enw/landing?follow=%40484tylso&lp=LzuXdt&liff_id=1657916768-pJgN5enw')">
          <div class="side-box">
            <img src="../../assets/SNS/line.png" style="height:50px;margin-left: 20px">
            <div class="side-text-line">
              <div style="font-size: 15px; text-align: center; width: 100%;">メディカルダイエット専用</div>
              <div style="font-size: 15px; text-align: center; width: 100%;">お友達登録＋診療予約</div>
            </div>
          </div>
        </div>

        <div class="bg_blue">
          <div class="m-center-title">料　金</div>
          <table class="m_table_ryoukin">
          <tr>
            <td class="ryoukin_top ryoukin_name_corner" style="font-size: 16px;">(税込価格)</td>
            <td class="ryoukin_top ryoukin_name_side m_ryoukin_title_top">３０日分</td>
            <td class="ryoukin_top ryoukin_name_side m_ryoukin_title_top">６０日分</td>
            <td class="ryoukin_top ryoukin_name_corner m_ryoukin_title_top">９０日分</td>
          </tr>
          <tr>
            <td class="ryoukin_name_topdown m_ryoukin_title">リベルサス®3mg</td>
            <td class="ryoukin_name">¥7,500</td>
            <td class="ryoukin_name">¥15,000</td>
            <td class="ryoukin_name_topdown">¥22,500</td>
          </tr>
          <tr>
            <td class="ryoukin_name_topdown m_ryoukin_title">リベルサス®7mg</td>
            <td class="ryoukin_name">¥13,900</td>
            <td class="ryoukin_name">¥27,800</td>
            <td class="ryoukin_name_topdown">¥41,700</td>
          </tr>
          <tr>
            <td class="ryoukin_name_topdown m_ryoukin_title">リベルサス®14mg</td>
            <td class="ryoukin_name">¥24,800</td>
            <td class="ryoukin_name">¥49,600</td>
            <td class="ryoukin_name_topdown">¥74,400</td>
          </tr>
          <tr>
            <td class="ryoukin_name_topdown m_ryoukin_title">カナグル®100mg</td>
            <td class="ryoukin_name">¥11,300</td>
            <td class="ryoukin_name">¥22,600</td>
            <td class="ryoukin_name_topdown">¥33,900</td>
          </tr>
          <tr>
            <td class="ryoukin_name_corner m_ryoukin_title">防風通聖散<br>1日3包</td>
            <td class="ryoukin_name_side">¥5,300</td>
            <td class="ryoukin_name_side">¥10,600</td>
            <td class="ryoukin_name_corner">¥15,900</td>
          </tr>
          </table>

          <div class="content1" style="height: 25px;"></div>

          <div class="content1">
            <div class="m-center-title" style="color: #FFFFFF;">その他にかかる費用</div>                 
          </div>

          <div class="content1">
            <table class="m_table_ryoukin">
              <tr>
                <td class="ryoukin_top ryoukin_name_corner" style="font-size: 16px;">(税込価格)</td>
                <td colspan="2" class="ryoukin_top ryoukin_name_side m_ryoukin_title_top">オンライン診療</td>
                <td class="ryoukin_top ryoukin_name_corner m_ryoukin_title_top">来院診療</td>
              </tr>
              <tr>
                <td class="ryoukin_name_topdown m_ryoukin_title">初　　診</td>
                <td colspan="2" class="ryoukin_name">￥2,200<br>(診療費＋送料)</td>
                <td class="ryoukin_name_topdown">￥1,100<br>(診療費)</td>
              </tr>
              <tr>
                <td class="ryoukin_name_corner m_ryoukin_title">再　　診</td>
                <td colspan="2" class="ryoukin_name_side">￥1,100<br>(診療費＋送料)</td>
                <td class="ryoukin_name_corner">￥  550<br>(診療費)</td>
              </tr>
          </table>
          </div>
          
        </div>

    </div>
    
    <m-departs/>
    <m-footer/>
  </div>
</div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import PageFooter from "@/components/PageFooter";
import DepartmentAside from "@/components/DepartmentAside";
import MTop from "../../components/m/MTop";
import MFooter from "../../components/m/MFooter";
import MDeparts from "../../components/m/MDeparts";
import DietSidebar from "@/components/DietSidebar";
import MDietSidebar from "../../components/m/MDietSidebar";
export default {
  name: "Price",
  components: {MDeparts, MFooter, MTop, DepartmentAside, PageFooter, PageHeader,DietSidebar,MDietSidebar},
  methods:{
    goHref(path) {
      window.location.href = path;
    },
  }
}
</script>

<style scoped>
/* PC */
.hidden-sm-and-down .img {
  margin-top: 30px;
}
.detail1 {
  margin: 40px 20px;
}
.content1 {
  margin-top: 30px;
}
.content1 .title1 {
  color: #CBB8B2;
  font-size: 18px;
  margin-bottom: 10px;
}
.content1 .text1 {
  color: #ebebeb;
  line-height: 1.5;
}
.other {
  color: #535353;
  font-size: 14px;
  line-height: 1.5;
}

.seperate_line{
  height:5px;
  border-top-color: #CBB8B2;
  border-top-width: 2px;
  border-top-style: solid;
  margin: 0 auto;
  width: 90%;
}

/* SP */
.m-main {
  background-color: #FFFFFF;
  border-top: 2px solid #CBB8B2;
  border-bottom: 2px solid #CBB8B2;
  padding: 2rem 0;
}
.m-title {
  color: #535353;
  font-size: 24px;
  text-align: center;
  margin: 2rem 0;
}
.m-detail {
  background-color: #FFFFFF;
  padding: 0 1rem;
}
.m-subtitle {
  color: #CBB8B2;
  font-size: 18px;
  padding: 2rem 0 1rem;
}
.m-content {
  line-height: 24px;
  color: #535353;
}

.center-title{
  color: #FFFFFF;
  font-size: 50px;
  text-align: center;
  width: 100%;
  margin-bottom: 10px;
}

.m-center-title{
  color: #FFFFFF;
  font-size: 25px;
  text-align: center;
  width: 100%;
  margin-bottom: 10px;
}

.LINE_btn{
  background-color: #20ce88;
  border-radius: 5px; 
  color: #FFFFFF;
  width:300px;
  height:50px;
  position: absolute;
  right: 0px;
  box-shadow: 5px 5px 10px #42859b,
  3px 3px 0px #20ce8878;
  cursor: pointer;
  transition: 150ms;
}

.LINE_btn:hover{
  background-color: #ffffff;
  color: #20ce88;
  box-shadow: 5px 5px 10px #42859b66,
  3px 3px 0px #20ce881b;
}
.m_LINE_btn{
  background-color: #20ce88;
  color: #FFFFFF;
  border-radius: 8px;
  width:80%;
  height:50px;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 4%;

  box-shadow: 5px 5px 10px #42859b,
  3px 3px 0px #20ce8878;
  ;
}
.m_LINE_btn:active{
  background-color: #ffffff;
  color: #20ce88;
  border-radius: 8px;
  width:350px;
  height:50px;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 4%;

  box-shadow: 5px 5px 10px #42859b,
  3px 3px 0px #20ce8878;
  ;
}

.banner_slogan{

  text-align: center;
  color: #326686;
  text-shadow: 1px 2px 0px #e9d5ce6b,
    0px 2px 3px #326686c4
    ;
  width: 100%;
}

.slogan_small{
  font-size: 15px;
  margin-top: 5px;
}

.slogan_big{
  font-size: 18px;
  margin-top: 15px;
}

.slogan_big_l{
  font-size: 30px;
  margin-top: 15px;
}

.slogan_large{
  font-size: 50px;  
}

.m_slogan_small{
  font-size: 5px;
  margin-top: 5px;
}

.m_slogan_big{
  font-size: 10px;
  margin-top: 5px;
}

.m_slogan_big_l{
  font-size: 12px;
  margin-top: 5px;
}

.m_slogan_large{
  font-size: 20px;  
}

.font_border{
  text-align: center;
  color: #326686;
  text-shadow: 
    0px 1px 4px #f5efe3,
    0px -1px 4px #f5efe3,
    1px 0px 4px #f5efe3,
    -1px 0px 4px #f5efe3;
  width: 100%;
}

.img_banner{
  position: relative;
  height: auto;
}

.table_ryoukin{
  font-size: 22px;
  color: #ffffff;
  width: 100%;
  margin: 0 auto;
  border-collapse:collapse;
}

.table_ryoukin tr{
  height:100px
}

.ryoukin_top{
  width: 25%;
}

.ryoukin_name{
  vertical-align: middle;
  border-color: #f1f1f1;
  border-width: 1px;
  border-style: solid;  
  text-align: center;
}

.ryoukin_name_side{
  vertical-align: middle;
  border-left-color: #f1f1f1;
  border-left-width: 1px;
  border-left-style: solid;  
  border-right-color: #f1f1f1;
  border-right-width: 1px;
  border-right-style: solid;  
  text-align: center;
}

.ryoukin_name_topdown{
  vertical-align: middle;
  border-top-color: #f1f1f1;
  border-top-width: 1px;
  border-top-style: solid;  
  border-bottom-color: #f1f1f1;
  border-bottom-width: 1px;
  border-bottom-style: solid;  
  text-align: center;
}

.ryoukin_name_corner{
  vertical-align: middle;
  
  text-align: center;
}

.ryoukin_title_top{
  font-size: 32px;
  color: #DFB382;
}

.ryoukin_title{
  font-size: 25px;
  color: #DFB382;
}

.m_table_ryoukin{
  color: #ffffff;
  width: 86%;
  margin: 0 auto;
  border-collapse:collapse;
  margin-left: 7%;
  font-size: 14px;
}

.m_table_ryoukin tr{
  height:40px
}

.m_ryoukin_name_top{
  font-size: 15px;
  text-align: center;
}

.m_ryoukin_title_top{
  font-size: 15px;
  color: #DFB382;
}

.m_ryoukin_title{
  font-size: 15px;
  color: #DFB382;
}



.bg_blue{
  background-color: #326686;
  width: 100%;
  height: auto;
  padding-top: 50px;
  padding-bottom: 50px;
}

.ryoukin_hint{
  color: #F71B1B;
  font-size: 16px;
  text-align: right;
  padding-right: 50px;
}

.title {
      font-size: 30px;
      letter-spacing: 5px;
      padding-top: 20px;
      padding-left: 20px;
    }

    .separator {
      color: #CBB8B2;
      width: 100%;
      margin: 0 auto;
      border-top: 4px solid;
      border-bottom: 4px solid;
    }

.side-img{
height: fit-content;
width:fit-content;
vertical-align:middle;
margin-left: 30px;
margin-right: 30px;
}

.side-text{
height: fit-content;
margin-left: 50px;
margin-right: 50px;
margin-bottom: auto;
}

.side-text-line{
  height: fit-content;
}



.side-box{
  display: inline-flex;
  justify-content: space-between;
  align-items:center;
  vertical-align: top;
  height: fit-content;
}

.side-line-highlight{
  background-color: #CBB8B2;
  width:6px;
  height: 25px;
  margin-bottom: 10px;
  margin-right: 10px;
}

</style>
